import EditProduct from "./EditProduct";


//Interfaces
interface Props {
    products: any;
    refresh:any;
}
export default function EditSearch({ products,refresh }: Props){
  
    return(
        <>
        {products.length > 0 ?
                        <table className="tableEdit" style={{width:"100%"}}>
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Warehouse</th>
                                    <th>Stock</th>
                                    <th>Order Code</th>
                                    <th>Case Barcode</th>
                                    <th>Case Size</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {products.map(function (d: any, i: number) {
                                    return (
                                        <EditProduct data={d} refresh={refresh}/>
                                    )
                                })}
                            </tbody>
                        </table>
                        : <h2>Could Not Find Any Matching Products</h2>
                    }
        </>
    )
}