//React
import { useState, useEffect } from "react";
import axios from "axios"
//Components
import MainAlert from "../reusables/MainAlert";
//Utils
import { handleAlert } from "../../utils/alert";
//MUI
import { Button, Modal } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
//Interfaces
interface Props {
    data: any;
    refresh: any;
}

export default function EditProduct({ data, refresh }: Props) {

    const [isWarehouse, setIsWarehouse] = useState<boolean>(false)
    const [caseBarcode, setCaseBarcode] = useState<any>(null)
    const [caseSize, setCaseSize] = useState<any>(null)
    const [orderCode, setOrderCode] = useState<any>(null)
    const [isEditing, setIsEditing] = useState<boolean>(false)
    //Alert
    const [alert, setAlert] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [severity, setSeverity] = useState<any>("");
    const [loader, setLoader] = useState<boolean>(true);
    const [disable, setDisable] = useState<boolean>(false);
    //Modals
    const [confirm, setConfirm] = useState<boolean>(false);
    const [confirmMessage, setConfirmMessage] = useState<string>("");

    useEffect(() => {
        if (data.caseBarcode == null) {
            setCaseBarcode("")
        } else {
            setCaseBarcode(data.caseBarcode)
        }
        if (data.orderCode == null) {
            setOrderCode("")
        } else {
            setOrderCode(data.orderCode)
        }
        if (data.caseSize == null) {
            setCaseSize("")
        } else {
            setCaseSize(data.caseSize)
        }
        setIsWarehouse(data.hasCraigAsSupplier)
    }, [data])

    const reset = async () => {
        setIsWarehouse(data.hasCraigAsSupplier)
        setIsEditing(false)
        if (data.caseBarcode == null) {
            setCaseBarcode("")
        } else {
            setCaseBarcode(data.caseBarcode)
        }
        if (data.orderCode == null) {
            setOrderCode("")
        } else {
            setOrderCode(data.orderCode)
        }
        if (data.caseSize == null) {
            setCaseSize("")
        } else {
            setCaseSize(data.caseSize)
        }
    }

    const saveUpdate = async () => {
        let prodData = {
            productId: data.productId,
            eposId: data.eposId,
            hasCraigAsSupplier: isWarehouse,
            orderCode: orderCode,
            caseBarcode: caseBarcode,
            caseSize:parseInt(caseSize)
        }

        await axios.post(`${process.env.REACT_APP_NITROPOS}/eposSQL/warehouse/product/update`, prodData).then((resp) => {
            handleAlert(
                "Successfully Submitted",
                "success",
                setMessage,
                setSeverity,
                setAlert
            );
            setIsEditing(false);
            refresh();
        }).catch((err) => {
            console.log("ERROR")
            handleAlert(
                "Unable To Update Product - Please try again",
                "error",
                setMessage,
                setSeverity,
                setAlert
            );
        })
    }

    return (
        <>
            <tr>
                <td>{data.name}</td>
                <td><input type="checkbox" checked={isWarehouse} onChange={() => setIsWarehouse(!isWarehouse)} disabled={!isEditing} /></td>
                <td>{data.stock}</td>
                <td><input type="text" placeholder="Order Code..." onChange={(e) => setOrderCode(e.target.value)} value={orderCode} disabled={!isEditing} /></td>
                <td><input type="text" placeholder="Case Barcode..." onChange={(e) => setCaseBarcode(e.target.value)} value={caseBarcode} disabled={!isEditing} /></td>
                <td><input type="number" placeholder="Case Size..." onChange={(e) => setCaseSize(e.target.value)} value={caseSize} disabled={!isEditing} /></td>
                {!isEditing ?
                    <td>
                        <Button variant="contained" color="primary" onClick={() => setIsEditing(!isEditing)}>
                            <SettingsIcon />
                        </Button>
                    </td>
                    :
                    <td style={{ display: "flex", justifyContent: "space-evenly" }}>
                        <Button variant="contained" color="error" onClick={reset}>
                            <HighlightOffIcon />
                        </Button>
                        <Button variant="contained" color="success" onClick={saveUpdate}>
                            <CheckCircleOutlineIcon />
                        </Button>
                    </td>
                }
            </tr>
            <Modal open={alert}>
                <MainAlert
                    message={message}
                    severity={severity}
                    closer={setAlert}
                />
            </Modal>
        </>
    )
}