//React
import { useState, useEffect } from "react";
//Components
import Loader from "../reusables/Loader";
import { getProductsBySupId } from "../../utils/api";
import EditProduct from "./EditProduct";
import "./edit.scss"
//Interfaces
interface Props {
    supplierId: number;
}
export default function EditSupplier({ supplierId }: Props) {

    const [products, setProducts] = useState<any>([])
    const [loader, setLoader] = useState<boolean>(true)
    const [reload, setReload] = useState<boolean>(false)

    useEffect(() => {

        getProductsBySupId(supplierId).then((res: any) => {
            if (res === "error") {
                console.log("error")
            } else {
                setProducts(res)
            }
            setLoader(false);
        });

    }, [reload])

    const refresh = async()=>{
        setReload(!reload)
    }


    return (
        <>
            {!loader ? (
                <>
                    {products.length > 0 ?
                        <table className="tableEdit" style={{width:"100%"}}>
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Warehouse</th>
                                    <th>Stock</th>
                                    <th>Order Code</th>
                                    <th>Case Barcode</th>
                                    <th>Case Size</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {products.map(function (d: any, i: number) {
                                    return (
                                        <EditProduct data={d} refresh={refresh}/>
                                    )
                                })}
                            </tbody>
                        </table>
                        : <h2>0 Products Found Under This Supplier</h2>
                    }

                </>
            ) : <Loader />
            }
        </>
    )
}