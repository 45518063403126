//React
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
//Styling
import "./picklist.scss";
//Mui
import { Modal, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
//Utils
import {
    getSites,
    getSuppliers,
    getPicklistStatuses,
    getAllPicklists,
} from "../../utils/api";
import { handleAlert } from "../../utils/alert";
//Components
import MainAlert from "../reusables/MainAlert";
import Loader from "../reusables/Loader";

export default function Picklist() {
    //URL
    const url = process.env.REACT_APP_NITROPOS;
    const navigate = useNavigate();
    //Picklists
    const [allPickLists, setAllPicklists] = useState<any>([]);
    const [allPickListsFixed, setAllPicklistsFixed] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    //Filters
    const [sites, setSites] = useState<any>([]);
    const [selectedSite, setSelectedSite] = useState<number>(0);
    const [suppliers, setSuppliers] = useState<any>([]);
    const [selectedSupplier, setSelectedSupplier] = useState<any>(0);
    const [statuses, setStatuses] = useState<any>([]);
    const [selectedStatus, setSelectedStatus] = useState<any>(0);
    //Alert & Loader
    const [alert, setAlert] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [severity, setSeverity] = useState<any>("");
    const [loader, setLoader] = useState<boolean>(true);

    useEffect(() => {
        //Get user authorised supplier from localstorage
        let _suppliers = [];
        let userDetails = localStorage.getItem("userDetails");
        if (userDetails) {
            let parsedUserDetails = JSON.parse(userDetails);
            if (parsedUserDetails.suppliers) {
                _suppliers = JSON.parse(parsedUserDetails.suppliers);
            }
        }
        console.log(userDetails);
        //If authorised will navigate to home in order to strip params
        navigate("/");
        //Bring in Picklists - 50 limit / page 1

        getAllPicklists(50, currentPage, _suppliers).then((res: any) => {
            if (res === "error") {
                handleAlert(
                    "Sorry unable to get picklists - Please try again",
                    "error",
                    setMessage,
                    setSeverity,
                    setAlert
                );
            } else {
                setAllPicklists(res);
                setAllPicklistsFixed(res);
                handleSupplierArray(res);
            }
            setLoader(false);
        });

        //Bring in sites
        getSites().then((res: any) => {
            if (res === "error") {
                handleAlert(
                    "Sorry unable to get sites",
                    "warning",
                    setMessage,
                    setSeverity,
                    setAlert
                );
            } else {
                setSites(res);
            }
        });

        //Bring in suppliers - Removed to allow Dynamic Pick List Supplier list
        // getSuppliers().then((res: any) => {
        //     if (res === "error") {
        //         handleAlert(
        //             "Sorry unable to get suppliers",
        //             "warning",
        //             setMessage,
        //             setSeverity,
        //             setAlert
        //         );
        //     } else {
        //         // setSuppliers(res);
        //         console.log(res);
        //     }
        // });

        //Bring in statuses
        getPicklistStatuses().then((res: any) => {
            if (res === "error") {
                handleAlert(
                    "Sorry unable to get statuses",
                    "warning",
                    setMessage,
                    setSeverity,
                    setAlert
                );
            } else {
                setStatuses(res);
            }
        });
    }, [currentPage]);

    // Handle unique suppliers from Pick Lists
    const handleSupplierArray = (pickLists: any[]) => {
        let _supp: any = [];

        for (let index = 0; index < pickLists.length; index++) {
            // Current Index of Array
            let currPick = pickLists[index];
            // Check if Supplier Exists in Local
            let supIndex = _supp.findIndex(
                (lSup: any) => lSup.eposId == currPick.supplierId
            );
            // Not found Push Unique Supplier details
            if (supIndex < 0) {
                _supp.push({
                    name: currPick.supplierName,
                    eposId: currPick.supplierId,
                });
            }
        }

        // Set supplier Details
        setSuppliers(_supp);
    };

    //Filter pending orders
    const filterOrders = (value: number, field: string) => {
        let filterData = {
            toLocation: field === "site" ? value : selectedSite,
            supplierId: field === "supplier" ? value : selectedSupplier,
            statusId: field === "status" ? value : selectedStatus,
        };

        //Remove any key/value pairs from the filtered data where the value is 0 (default)
        const removeZeros = (item: any) =>
            Object.keys(item)
                .filter((key) => item[key] !== 0)
                .reduce((newObj: any, key) => {
                    newObj[key] = item[key];
                    return newObj;
                }, {});
        const result = removeZeros(filterData);

        //Create a new filter array of the key value pairs that have to be filtered
        let filteredArray: any = [];
        for (const [key, value] of Object.entries(result)) {
            filteredArray.push({ key: key, value: value });
        }

        //Loop through filter array and set new array
        let finalFiltered = allPickListsFixed;
        for (let index = 0; index < filteredArray.length; index++) {
            finalFiltered = finalFiltered.filter(
                (order: any) =>
                    order[filteredArray[index].key] ==
                    filteredArray[index].value
            );
        }

        //Set Current filter selects
        if (field === "site") {
            setSelectedSite(value);
        } else if (field === "supplier") {
            setSelectedSupplier(value);
        } else if (field === "status") {
            setSelectedStatus(value);
        }
        //Set new filtered pending orders array
        setAllPicklists(finalFiltered);
    };

    return (
        <main className="picklist">
            <h1 className="main_heading">Warehouse Picklists</h1>{" "}
            {!loader ? (
                <>
                    <section className="filter_container">
                        {" "}
                        <select
                            onChange={(e) => {
                                filterOrders(parseInt(e.target.value), "site");
                            }}
                        >
                            <option value={0}>Filter by site</option>
                            {sites &&
                                sites.map((site: any, i: number) => {
                                    return (
                                        <option key={i} value={site.epos}>
                                            {site.name}
                                        </option>
                                    );
                                })}
                        </select>
                        <select
                            onChange={(e) => {
                                filterOrders(
                                    parseInt(e.target.value),
                                    "supplier"
                                );
                            }}
                        >
                            <option value={0}>Filter by Supplier</option>
                            {suppliers &&
                                suppliers.map((supplier: any, i: number) => {
                                    return (
                                        <option key={i} value={supplier.eposId}>
                                            {supplier.name}
                                        </option>
                                    );
                                })}
                        </select>
                        <select
                            onChange={(e) => {
                                filterOrders(
                                    parseInt(e.target.value),
                                    "status"
                                );
                            }}
                        >
                            {" "}
                            <option value={0}>Filter by Status</option>
                            {statuses &&
                                statuses.map((status: any, i: number) => {
                                    return (
                                        <option key={i} value={status.statusId}>
                                            {status.name}
                                        </option>
                                    );
                                })}
                        </select>{" "}
                    </section>
                    {allPickLists.length > 0 ? (
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>From</th>
                                    <th>To</th>
                                    <th>Supplier</th>
                                    <th>Product Total</th>
                                    <th>Status</th>
                                    <th>Date Created</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allPickLists &&
                                    allPickLists.map(
                                        (picklist: any, i: any) => {
                                            return (
                                                <tr
                                                    style={{
                                                        backgroundColor:
                                                            picklist.statusId ===
                                                            3
                                                                ? "#b6fcc9"
                                                                : "transparent",
                                                    }}
                                                    onClick={() => {
                                                        if (
                                                            picklist.statusId !==
                                                            3
                                                        ) {
                                                            navigate(
                                                                `${picklist.plHeadId}`
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {" "}
                                                    <td>
                                                        {
                                                            picklist.fromLocationName
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            picklist.toLocationName
                                                        }
                                                    </td>
                                                    <td>
                                                        {picklist.supplierName}
                                                    </td>
                                                    <td>
                                                        {picklist.productTotal}
                                                    </td>
                                                    <td>
                                                        {picklist.statusName}
                                                    </td>
                                                    <td>
                                                        {moment(
                                                            picklist.dateCreated
                                                        ).format(
                                                            "DD-MM-YY HH:mm"
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )}
                            </tbody>
                        </table>
                    ) : (
                        <h1 className="empty_table_heading">
                            There are no picklist orders
                        </h1>
                    )}
                </>
            ) : (
                <Loader />
            )}
            <section className="pagination_btns">
                {" "}
                <Button
                    onClick={() => {
                        setCurrentPage(currentPage + 1);
                        window.scrollTo(0, 0);
                    }}
                    disabled={allPickLists.length < 50}
                    variant="contained"
                >
                    <ArrowBackIcon />
                </Button>
                <h2>{currentPage}</h2>
                <Button
                    onClick={() => {
                        setCurrentPage(currentPage - 1);
                        window.scrollTo(0, 0);
                    }}
                    disabled={currentPage === 1}
                    variant="contained"
                >
                    <ArrowForwardIcon />
                </Button>
            </section>
            <Modal open={alert}>
                <MainAlert
                    message={message}
                    severity={severity}
                    closer={setAlert}
                />
            </Modal>
        </main>
    );
}
