//React
import { Link } from "react-router-dom";
//Styling
import "./styling/footer.scss";

export default function Footer() {
    return (
        <footer>
            <h4 className="footer_nitro">
                Designed & Developed By{" "}
                <Link to="https://nitrosolutions.co.uk/" className="nitro_link">
                    Nitro Solutions
                </Link>
            </h4>
        </footer>
    );
}
