// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: "row";
  gap: "10px";
  background-color: #2c53a0;
  height: 6vh;
  color: #fff;
}
footer .footer_nitro .nitro_link {
  color: #e4ffff;
  text-decoration: none;
}
footer .footer_nitro .nitro_link:hover {
  color: #64c4e0;
}
@media (max-width: 1000px) {
  footer {
    height: 11vh;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/styling/footer.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAEA;EC2BI,aAAA;EACA,uBD3Bc;EC4Bd,mBD5BsB;EC6BtB,qBANY;EAOZ,WANM;EDvBN,yBCHQ;EDIR,WCUY;EDTZ,WCCI;ADER;AADQ;EACI,cCNC;EDOD,qBAAA;AAGZ;AADQ;EACI,cCXC;ADcb;AACI;EAfJ;IAgBQ,YAAA;EAEN;AACF","sourcesContent":["@import \"../../variables.scss\";\n\nfooter {\n    @include flex(center, center);\n    background-color: $nitroDark;\n    height: $footer-height;\n    color: $white;\n    .footer_nitro {\n        .nitro_link {\n            color: $background;\n            text-decoration: none;\n        }\n        .nitro_link:hover {\n            color: $nitroLight;\n        }\n    }\n\n    @media (max-width: $tablet) {\n        height: 11vh;\n    }\n}\n","//Colors\n$nitroDark: #2c53a0;\n$nitroLight: #64c4e0;\n$background: #e4ffff;\n$fontColor: #4d4d4d;\n$error: #bb0000;\n$warning: #f29422;\n$white: #fff;\n$black: #000;\n$success: #b6fcc9;\n$green: #357a38;\n\n//Heights\n$nav-height: 10vh;\n$main-height: 89vh;\n$footer-height: 6vh;\n\n//Breakpoints\n$mobile: 600px;\n$tablet: 1000px;\n$desktop: 1300px;\n\n//Layout\n@mixin flex(\n    $justify: \"center\",\n    $align: \"center\",\n    $direction: \"row\",\n    $gap: \"10px\"\n) {\n    display: flex;\n    justify-content: $justify;\n    align-items: $align;\n    flex-direction: $direction;\n    gap: $gap;\n}\n\n//Overflow\n@mixin scrollbars(\n    $size,\n    $foreground-color,\n    $background-color: mix($foreground-color, white, 50%)\n) {\n    // For Google Chrome\n    &::-webkit-scrollbar {\n        width: $size;\n        height: $size;\n    }\n\n    &::-webkit-scrollbar-thumb {\n        background: $foreground-color;\n    }\n\n    &::-webkit-scrollbar-track {\n        background: $background-color;\n    }\n\n    // For Internet Explorer\n    & {\n        scrollbar-face-color: $foreground-color;\n        scrollbar-track-color: $background-color;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
