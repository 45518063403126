//React
import { useState } from "react";
//Utils
import { axiosInstance } from "../../utils/axiosInstance";
import { numberValidation } from "../../utils/validation";
//Mui
import { Button } from "@mui/material";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { handleAlert } from "../../utils/alert";
import Modal from "@mui/material/Modal";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Fade from "@mui/material/Fade";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
//Components
import MainAlert from "../reusables/MainAlert";
//Interfaces
interface Props {
    product: any;
    user: string;
    handleLoadPicklist: any;
}

export default function PicklistRow({
    product,
    user,
    handleLoadPicklist,
}: Props) {
    //URL
    const url = process.env.REACT_APP_NITROPOS;
    //Main
    const [actual, setActual] = useState<number>(
        product.saved
            ? product.picked
            : product.caseSize
                ? Math.ceil(product.qtyOrdered / product.caseSize)
                : product.qtyOrdered
    );
    const [isDone, setIsDone] = useState<Boolean>(product.saved);
    //Row Confirm Dialog
    const [rowConfirm, setRowConfirm] = useState<any>(false);
    //Alert
    const [alert, setAlert] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [severity, setSeverity] = useState<any>("");

    const handleSaveRowToggle = (toggle: boolean) => {
        if (isNaN(actual) && toggle) {
            setActual(0);
        }
        let data = {
            plHeadId: product.plHeadId,
            plItemId: product.plItemId,
            saved: toggle,
            picked: !toggle ? 0 : isNaN(actual) ? 0 : actual,
            user: user,
        };

        axiosInstance
            .post(`${url}/picklist/products/update`, data)
            .then((res) => {
                setIsDone(toggle);
                handleLoadPicklist(toggle);
            })
            .catch((err) => {
                console.log(err);
                handleAlert(
                    "Unable to update row please try again",
                    "error",
                    setMessage,
                    setSeverity,
                    setAlert
                );
            });
    };

    return (
        <>
            {product.image && (
                <img src={product.image} className="product_image_mobile" />
            )}
            <tr style={{ backgroundColor: isDone && "#b6fcc9" }}>
                <td> <span className="product_name">{product.name}</span>
                    <Tooltip
                        arrow
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        placement="top-start"
                        title={`Barcode(s): ${product.barcode ? product.barcode : "None"
                            } | Case Barcode: ${product.caseBarcode ? product.caseBarcode : "None"
                            } | Case Size: ${product.caseSize ? product.caseSize : "None"
                            }`}
                    >
                        <IconButton>
                            <InfoIcon fontSize="large" className="info_btn" />
                        </IconButton>
                    </Tooltip>
                </td>
                <td className="product_qty">
                    <b>
                        {product.caseSize
                            ? Math.ceil(product.qtyOrdered / product.caseSize)
                            : product.qtyOrdered}
                        {product.caseSize == null ? " SINGLE(S)" : " CASE(S)"}
                    </b>
                </td>
                <td className="product_qty">
                    <input
                        className="input_number"
                        type="number"
                        onKeyDown={(e) => {
                            numberValidation(e);
                        }}
                        min="0"
                        onChange={(e) => {
                            if (
                                parseInt(e.target.value) <= 999 ||
                                e.target.value === ""
                            ) {
                                setActual(parseInt(e.target.value));
                                handleSaveRowToggle(false);
                            }
                        }}
                        value={actual}
                    />
                </td>{" "}

                <td className="image_on_desktop">
                    {product.image ? (
                        <img src={product.image} />
                    ) : (
                        <DoNotDisturbIcon
                            sx={{ color: "#2c53a0" }}
                            fontSize="large"
                        />
                    )}
                </td>
                <td>
                    <Button
                        sx={{ maxHeight: "80%" }}
                        variant="contained"
                        color="success"
                        onClick={() => {
                            if (isDone) {
                                handleSaveRowToggle(!isDone);
                            } else {
                                if (product.caseSize) {
                                    if (
                                        product.qtyOrdered /
                                        product.caseSize !==
                                        actual
                                    ) {
                                        setRowConfirm(true);
                                    } else {
                                        handleSaveRowToggle(true);
                                    }
                                } else {
                                    if (product.qtyOrdered !== actual) {
                                        setRowConfirm(true);
                                    } else {
                                        handleSaveRowToggle(true);
                                    }
                                }
                            }
                        }}
                    >
                        <DoneOutlineIcon />
                    </Button>
                </td>
                {/* MODALS */}
                <Modal open={alert}>
                    <MainAlert
                        message={message}
                        severity={severity}
                        closer={setAlert}
                    />
                </Modal>
                <Dialog open={rowConfirm} className="confirm_row_dialog">
                    <DialogTitle className="confirm_row_header">
                        Picked Vs Ordered Discrepency
                    </DialogTitle>
                    <section className="confirm_row_content">
                        The amount you have picked is not the quantity that was
                        ordered. Are you sure you want to save this pick?
                    </section>
                    <section className="confirm_row_btns">
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => setRowConfirm(false)}
                        >
                            No, go back
                        </Button>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={() => {
                                handleSaveRowToggle(true);
                                setRowConfirm(false);
                            }}
                        >
                            Yes, I'm sure
                        </Button>
                    </section>
                </Dialog>
            </tr>
        </>
    );
}
