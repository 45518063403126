//React
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//Sentry
import { configSentry } from "./utils/sentry";
//Styling
import "./App.css";
import "./reuseables.scss";
//Components
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import PickList from "./components/picklist/Picklist";
import IndvPickList from "./components/picklist/IndvPickList";
import OffLimits from "./components/auth/OffLimits";
import { WarehouseProtect } from "./components/auth/WarehouseProtect";
import QuickEdit from "./components/quickEdit/QuickEdit";
import ManualEDN from "./components/EDNs/ManualEDN";

configSentry();

function App() {
    return (
        <div className="App">
            <Router>
                <Nav />
                <Routes>
                    <Route element={<WarehouseProtect />}>
                        <Route path="/quick-edit" element={<QuickEdit />} />
                        <Route path="/" element={<PickList />} />
                        <Route path="/:id" element={<IndvPickList />} />
                        <Route path="/manual-edn" element={<ManualEDN />} />
                    </Route>
                    <Route path="/off-limits" element={<OffLimits />} />
                </Routes>
                <Footer />
            </Router>
        </div>
    );
}

export default App;
