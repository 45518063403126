//React
import { Navigate, Outlet } from "react-router-dom";

const WarehouseProtect = () => {
    const url = process.env.REACT_APP_NITROPOS
    let authorised = false;
    let userDetails = localStorage.getItem("userDetails");

    //If local storage authorises user using this else it will strip params and use them to authorise user
    if (userDetails !=null) {
        let parsedUser = JSON.parse(userDetails);
        if (
            parsedUser.role === "Warehouse Manager" ||
            parsedUser.role === "Developer" ||
            parsedUser.role === "V4U Manager" ||
            parsedUser.role.includes("Packer") ||
            parsedUser.role === "Warehouse Assistant" ||
            parsedUser.user === "guna@ghsl.uk"
        ) {
            authorised = true;
        }
    } else {
        //Strips the Params into an object
        let params: any = window.location.search;
        if (params) {
            params = params.split("?")[1];
            params =
                '{"' + params.replace(/&/g, '", "').replace(/=/g, '":"').replace(/%20/g, " ") + '"}';
            params = JSON.parse(params);

            if (
                params.role === "Warehouse Manager" ||
                params.role === "Developer" ||
                params.role === "V4U Manager" ||
                params.role.includes("Packer") ||
                params.role === "Warehouse Assistant" ||
                params.user === "guna@ghsl.uk"
            ) {
                authorised = true;
                localStorage.setItem("userDetails", JSON.stringify(params));
            }
        }
    }
    
    if (!authorised) {
        return <Navigate to="/off-limits" replace />;
    }
    return <Outlet />;
};

export { WarehouseProtect };
