//React
import { useState, useEffect } from "react";
//MUI
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Modal } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
//Components
import MainAlert from "../reusables/MainAlert";
//Utils
import { getProductsByVal, getSuppliers } from "../../utils/api";
import { handleAlert } from "../../utils/alert";
//Components
import Loader from "../reusables/Loader";
import EditSupplier from "./EditSupplier";
import EditSearch from "./EditSearch";

export default function QuickEdit() {

    const [loader, setLoader] = useState<boolean>(true);
    const [suppliers, setSuppliers] = useState<any>([]);
    const [expanded, setExpanded] = useState<any>(null);
    const [searchVal, setSearchVal] = useState<string>("")
    const [showResults, setShowResults] = useState<boolean>(false);
    const [resLoader, setResLoader] = useState<boolean>(false)
    const [resData, setResData] = useState<any>([])
    //Alert
    const [alert, setAlert] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [severity, setSeverity] = useState<any>("");

    useEffect(() => {

        getSuppliers().then((res: any) => {
            if (res === "error") {
                console.log("error")
            } else {
                setSuppliers(res)
            }
            setLoader(false);
        });

    }, [])

    const handleChange = (idx: number) => {
        if (idx == expanded) {
            setExpanded(null);
        } else {
            setExpanded(idx);
        }
    };

    const searchProduct = async () => {
        if (searchVal == "") {
            handleAlert(
                "Product Details Cannot Be Empty!",
                "warning",
                setMessage,
                setSeverity,
                setAlert
            );
        } else {
            setShowResults(true)
            setResLoader(true)
            await getProductsByVal(searchVal).then((res: any) => {
                if (res === "error") {
                    handleAlert(
                        "Something went wrong - Please try again",
                        "error",
                        setMessage,
                        setSeverity,
                        setAlert
                    );
                } else {
                    setResData(res)
                }
                setResLoader(false)
            });
        }
    }

    const handleKeyDown = async (event: any) => {
        if (event.key === "Enter") {
            await searchProduct();
        }
    };

    const resetSearch = ()=>{
        setShowResults(false)
        setSearchVal("")
        setResData([])
    }

    return (
        <main className="picklist">
            <h1 className="main_heading">Warehouse Quick Edit</h1>{" "}
            {!loader ? (
                <div style={{ padding: "2%" }}>
                    <input type="text"
                        style={{ fontSize: "1.5rem", borderRadius: "10px", border: "2px solid navy", margin: "1%" }} autoFocus={true}
                        placeholder="Search For Product...." onChange={(e) => setSearchVal(e.target.value)} value={searchVal}
                        onKeyDown={handleKeyDown}
                    />
                    {showResults ?
                        <Button variant="contained" color="error" sx={{ marginTop: "-0.5%", marginRight:"1%" }} onClick={resetSearch}>
                            <ClearIcon />
                        </Button>
                        : null}
                    <Button variant="contained" color="primary" sx={{ marginTop: "-0.5%" }} onClick={searchProduct}>
                        <SearchIcon />
                    </Button>
                    <br />
                    {showResults ?
                        <>
                            {resLoader ?
                                <Loader /> : <EditSearch products={resData} refresh={searchProduct}/>
                            }
                        </>
                        :
                        null
                    }
                    <br />
                    {suppliers.map(function (d: any, i: number) {
                        return (
                            <Accordion expanded={expanded === i}
                                onChange={() => handleChange(i)}
                                sx={{ width: "100%", margin: "0 auto" }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <p>{d.name}</p>
                                </AccordionSummary>
                                <hr />
                                {expanded === i ?
                                    <AccordionDetails sx={{ margin: "0 auto" }}>
                                        <EditSupplier supplierId={d.eposId} />
                                    </AccordionDetails>
                                    : null
                                }
                            </Accordion>
                        )
                    })}
                </div>
            ) : (
                <Loader />
            )}
            <Modal open={alert}>
                <MainAlert
                    message={message}
                    severity={severity}
                    closer={setAlert}
                />
            </Modal>
        </main>
    )
}