//React
import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
//Styling
import "./picklist.scss";
//MUI
import { Box, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//Components
import MainAlert from "../reusables/MainAlert";
import Loader from "../reusables/Loader";
import PicklistRow from "./PicklistRow";
import ConfirmModal from "../reusables/ConfirmModal";
//Utils
import { handleAlert } from "../../utils/alert";
import { getPickListById } from "../../utils/api";
import { axiosInstance } from "../../utils/axiosInstance";

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "8px",
    p: 4,
};

export default function IndvPickList() {
    //URL
    const url = process.env.REACT_APP_NITROPOS;
    const navigate = useNavigate();
    //Main
    const [user, setUser] = useState<any>({});
    //Params
    const { id } = useParams();
    //Refs
    const scrollToTopRef: any = useRef();
    //Picklist
    const [picklist, setPicklist] = useState<any>({});
    const [products, setProducts] = useState<any>([]);
    const [pickListLength, setPickListLength] = useState<number>(0);
    const [currentPick, setCurrentPick] = useState<number>(1);
    const [picksDone, setPicksDone] = useState<number>(0);
    const [boxes, setBoxes] = useState<any>(null);
    const [ogCases, setOgCases] = useState<number>(0);
    const [actualCases, setActualCases] = useState<number>(0);
    //Alert
    const [alert, setAlert] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [severity, setSeverity] = useState<any>("");
    const [loader, setLoader] = useState<boolean>(true);
    const [disable, setDisable] = useState<boolean>(false);
    //Modals
    const [confirm, setConfirm] = useState<boolean>(false);
    const [confirmMessage, setConfirmMessage] = useState<string>("");
    const [showBoxes, setShowBoxes] = useState<boolean>(false);
    const [showCases, setShowCases] = useState<boolean>(false);

    useEffect(() => {
        setLoader(true);
        //Bring in User
        let userDetails = localStorage.getItem("userDetails");
        if (userDetails) {
            let parsedUserDetails = JSON.parse(userDetails);
            setUser(parsedUserDetails);
        }
        //Bring in Picklist
        handleLoadPicklist(false);
        setLoader(false);
    }, []);

    const handleLoadPicklist = (toggle: boolean) => {
        //Scroll to top of screen after every pick save
        if (toggle) {
            scrollToTopRef.current.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
        console.log(id);
        getPickListById(id).then((res) => {
            if (res === "error") {
                setDisable(true);
                handleAlert(
                    "Unable to get picklist - Please try again",
                    "error",
                    setMessage,
                    setSeverity,
                    setAlert
                );
                setTimeout(() => {
                    navigate(`/${id}`);
                }, 3000);
                setLoader(false);
            } else {
                console.log(res);
                setPicklist(res);
                setProducts(res.products);
                setPickListLength(res.products.length);

                var cases = 0;
                for (let i = 0; i < res.products.length; i++) {
                    if (res.products[i].caseSize) {
                        cases += Math.ceil(
                            res.products[i].qtyOrdered /
                                res.products[i].caseSize
                        );
                    } else {
                        cases += res.products[i].qtyOrdered;
                    }
                }
                console.log(cases);
                setOgCases(cases);

                //Check all rows have been saved - User can not proceed until this is done.
                let picksDone = 0;
                res.products.filter((pick: any, i: number) => {
                    pick.saved && picksDone++;
                });
                setPicksDone(picksDone);
            }
        });
    };

    const handleValidation = () => {
        //Check all rows have been saved - User can not proceed until this is done.
        let allDoneCheck = picklist.products.every((pick: any, i: number) => {
            return pick.saved;
        });

        //Check if all expected values match actual picked - Just to advise user incase not meant
        let mismatchCheck = picklist.products.every((pick: any, i: number) => {
            return pick.picked === pick.qtyOrdered;
        });
        //Check if any actual values left at 0 - Just to advise user incase was not meant
        let zeroCheck = picklist.products.every((pick: any, i: number) => {
            return pick.picked === 0;
        });

        if (!allDoneCheck) {
            handleAlert(
                "Please mark all picklist items as done before proceeding",
                "warning",
                setMessage,
                setSeverity,
                setAlert
            );
        } else if (!mismatchCheck || zeroCheck) {
            setConfirmMessage(
                "One or more of your pick quantites are different from the amount ordered. Are you sure you are happy to proceed?"
            );
            setConfirm(true);
        } else {
            handleSubmit();
        }
    };

    const handleSubmit = () => {
        if (picklist.supplierName == "Nitro V4U/Snus PO" && boxes == 0) {
            setShowBoxes(true);
        } else if (
            picklist.supplierName != "Nitro V4U/Snus PO" &&
            showCases == false
        ) {
            let cases = 0;
            for (let i = 0; i < picklist.products.length; i++) {
                if (picklist.products[i].caseSize) {
                    cases += Math.ceil(
                        picklist.products[i].qtyOrdered /
                            picklist.products[i].caseSize
                    );
                } else {
                    cases += picklist.products[i].qtyOrdered;
                }
            }
            setActualCases(cases);
            setShowCases(true);
        } else {
            setShowCases(false);
            setShowBoxes(false);
            setLoader(true);
            setDisable(true);
            let data = {
                user: user.user,
                plHeadId: picklist.plHeadId,
                products: picklist.products,
                statusId: 3,
                boxes: boxes,
            };
            console.log(data);
            axiosInstance
                .post(`${url}/picklist/update`, data)
                .then((res) => {
                    handleAlert(
                        "Successfully Submitted",
                        "success",
                        setMessage,
                        setSeverity,
                        setAlert
                    );
                    navigate("/");
                    setLoader(false);
                })
                .catch((err) => {
                    console.log(err);
                    handleAlert(
                        "Error - Unable to submit, please try again",
                        "error",
                        setMessage,
                        setSeverity,
                        setAlert
                    );
                    setLoader(false);
                    setDisable(false);
                });
        }
    };

    return (
        <main className="individual_picklist" ref={scrollToTopRef}>
            <h1 className="main_heading">PickList</h1>
            {!loader ? (
                <>
                    <br />
                    <Accordion sx={{ width: "95%", margin: "0 auto" }}>
                        <AccordionSummary
                            expandIcon={
                                <ExpandMoreIcon
                                    fontSize="large"
                                    sx={{ color: "#2c53a0" }}
                                />
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            Details
                        </AccordionSummary>
                        <AccordionDetails>
                            <section className="header_details">
                                <div>
                                    <h3>From</h3>
                                    <h4>{picklist.fromLocationName}</h4>
                                </div>
                                <div>
                                    <h3>To</h3>
                                    <h4>{picklist.toLocationName}</h4>
                                </div>
                                <div>
                                    <h3>Supplier</h3>
                                    <h4>{picklist.supplierName}</h4>
                                </div>
                                <div>
                                    <h3>Created on</h3>
                                    <h4>
                                        {moment(picklist.dateCreated).format(
                                            "DD-MM-YY HH:mm"
                                        )}
                                    </h4>
                                </div>
                                <div>
                                    <h3>Picklist ID</h3>
                                    <h4>{picklist.plHeadId}</h4>
                                </div>
                            </section>{" "}
                        </AccordionDetails>
                    </Accordion>

                    <section className="page_section">
                        {" "}
                        <h3>
                            Page:{" "}
                            <span>
                                {currentPick}/{pickListLength}
                            </span>
                        </h3>
                        <h3>
                            Done:{" "}
                            <span
                                style={{
                                    color:
                                        picksDone === pickListLength
                                            ? "#357a38"
                                            : "#bb0000",
                                }}
                            >
                                {picksDone}
                            </span>
                            /
                            <span style={{ color: "#357a38" }}>
                                {pickListLength}
                            </span>
                        </h3>
                    </section>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>Ordered</th>
                                <th>Picked</th>
                                <th>Image</th>
                                <th>Done</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* If a full Picklist table is need dependant on role, add a user conditional here that removes the index dependency */}
                            {products &&
                                products.map((product: any, i: number) => {
                                    if (currentPick - 1 === i) {
                                        return (
                                            <PicklistRow
                                                product={product}
                                                user={user.user}
                                                handleLoadPicklist={
                                                    handleLoadPicklist
                                                }
                                            />
                                        );
                                    }
                                })}
                        </tbody>
                    </table>
                    <section className="nav_btns">
                        <Button
                            variant="contained"
                            onClick={() => {
                                if (currentPick > 1) {
                                    setCurrentPick(currentPick - 1);
                                }
                            }}
                            disabled={currentPick === 1}
                        >
                            <ArrowBackIcon />
                        </Button>
                        <Button
                            className="submit_btn"
                            variant="contained"
                            color="success"
                            onClick={handleValidation}
                            disabled={disable || picksDone != pickListLength}
                        >
                            Submit
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                if (currentPick < pickListLength) {
                                    setCurrentPick(currentPick + 1);
                                }
                            }}
                            disabled={currentPick === pickListLength}
                        >
                            <ArrowForwardIcon />
                        </Button>
                    </section>
                </>
            ) : (
                <Loader />
            )}
            {/* MODALS */}
            <Modal open={alert}>
                <MainAlert
                    message={message}
                    severity={severity}
                    closer={setAlert}
                />
            </Modal>
            <Modal open={confirm}>
                <ConfirmModal
                    close={setConfirm}
                    handleSubmit={handleSubmit}
                    message={confirmMessage}
                    disable={disable}
                />
            </Modal>
            <Modal open={showBoxes}>
                <Box sx={style}>
                    <h2>Enter Number Of Boxes Used To Pack Vapes:</h2>
                    <input
                        style={{ width: "95%" }}
                        type="number"
                        placeholder="Number Of Boxes....."
                        value={boxes}
                        onChange={(e) => setBoxes(parseInt(e.target.value))}
                        autoFocus={true}
                    />
                    <hr />
                    <section
                        style={{
                            display: "flex",
                            justifyContent: "space-around",
                        }}
                    >
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => setShowBoxes(false)}
                        >
                            Go Back
                        </Button>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </section>
                </Box>
            </Modal>
            <Modal open={showCases}>
                <Box sx={style}>
                    <h2 style={{ textAlign: "center" }}>
                        Total Cases In Order
                    </h2>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-around",
                        }}
                    >
                        <h3>Ordered: {ogCases}</h3>

                        <h3>Picked: {actualCases}</h3>
                    </div>
                    <hr />
                    <section
                        style={{
                            display: "flex",
                            justifyContent: "space-around",
                        }}
                    >
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => setShowCases(false)}
                        >
                            Go Back
                        </Button>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </section>
                </Box>
            </Modal>
        </main>
    );
}
