//Styling
import "./auth.scss";
//Mui
import BlockIcon from "@mui/icons-material/Block";

export default function OffLimits() {
    return (
        <main className="off_limits">
            <BlockIcon className="off_limits_logo" />
            <h1>This Page is off Limits</h1>
        </main>
    );
}
