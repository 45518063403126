//REACT
import { useState, useEffect } from "react";
//Components
import MainAlert from "../reusables/MainAlert";
import Loader from "../reusables/Loader";
//Utils
import { handleAlert } from "../../utils/alert";
import { axiosInstance } from "../../utils/axiosInstance";
import { getSites } from "../../utils/api";
import { numberValidation } from "../../utils/validation";
//Styling
import "./manual-edn.scss";
//MUI
import { Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Modal } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";

export default function ManualEDN() {
    //Main
    // const url = "http://10.0.0.174:4022";
    const url = process.env.REACT_APP_NITROPOS;
    const [userName, setUserName] = useState<string>("");
    //Search Input
    const [fromLocation, setFromLocation] = useState<number>(0);
    const [toLocation, setToLocation] = useState<number>(0);
    const [reason, setReason] = useState<number>(0);
    const [selectedProduct, setSelectedProduct] = useState<string>("");
    //Sites
    const [siteList, setSiteList] = useState<any[]>([]);
    //Products
    const [possibleProducts, setPossibleProducts] = useState<any[]>([]);
    const [chosenProducts, setChosenProducts] = useState<any[]>([]);
    //Alert & Loader
    const [alert, setAlert] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [severity, setSeverity] = useState<any>("");
    const [productLoader, setProductLoader] = useState<boolean>(false);
    const [submitLoader, setSubmitLoader] = useState<boolean>(false);
    const [disable, setDisable] = useState<boolean>(false);

    useEffect(() => {
        //Set User
        let _userDetails = localStorage.getItem("userDetails");
        if (_userDetails) {
            let _parsedUserDetails = JSON.parse(_userDetails);
            setUserName(_parsedUserDetails.user);
        }

        //Get SiteList
        getSites().then((res) => {
            setSiteList(res);
        });
    }, []);

    const handleProductSearch = async () => {
        if (selectedProduct === "") {
            handleAlert(
                "Please enter an name or barcode",
                "warning",
                setMessage,
                setSeverity,
                setAlert
            );
            setPossibleProducts([]);
        } else {
            setProductLoader(true);
            axiosInstance
                .post(`${url}/eposProducts/searchProduct`, {
                    name: selectedProduct,
                    barcode: selectedProduct,
                })
                .then((res) => {
                    if (res.data.length > 0) {
                        setPossibleProducts(res.data);
                    } else {
                        handleAlert(
                            "Can't find any products with this information",
                            "warning",
                            setMessage,
                            setSeverity,
                            setAlert
                        );
                        setPossibleProducts([]);
                    }
                    setProductLoader(false);
                })
                .catch((err) => {
                    console.log(err);
                    handleAlert(
                        "Something went wrong - Please try again",
                        "error",
                        setMessage,
                        setSeverity,
                        setAlert
                    );
                    setProductLoader(false);
                });
        }
    };

    const handleAddProduct = (product: any) => {
        //Check if product already in chosen products array
        let dupeCheck = chosenProducts.filter(
            (chosenProduct: any, i: number) => {
                return chosenProduct.productId === product.productId;
            }
        );

        if (dupeCheck.length > 0) {
            handleAlert(
                "This product has already been added",
                "warning",
                setMessage,
                setSeverity,
                setAlert
            );
        } else {
            let chosenProductsCopy = chosenProducts;

            setChosenProducts([product, ...chosenProductsCopy]);
        }
    };

    const handleQtyUpdate = (id: number, qty: number) => {
        let newProductList = chosenProducts.map(
            (chosenProduct: any, i: number) => {
                if (chosenProduct.productId === id) {
                    return {
                        ...chosenProduct,
                        originalToOrder: qty,
                        actualToOrder: qty,
                    };
                } else {
                    return chosenProduct;
                }
            }
        );
        setChosenProducts(newProductList);
    };

    const handleRemoveProduct = (product: any) => {
        let filtered = chosenProducts.filter(
            (chosenProduct: any, i: number) => {
                return product.productId != chosenProduct.productId;
            }
        );
        setChosenProducts(filtered);
    };

    const handleValidation = () => {
        //Will do a check on every product in chosen products array to ensure it has a qty greater than zero
        let qtyCheck = chosenProducts.every((chosenProduct: any, i: number) => {
            return chosenProduct.actualToOrder;
        });

        if (!qtyCheck) {
            handleAlert(
                "One or more of the products you have chosen do not have quantities greater than zero, please amend and try again",
                "warning",
                setMessage,
                setSeverity,
                setAlert
            );
        } else if (toLocation === fromLocation) {
            handleAlert(
                "To and From locations can not be the same",
                "warning",
                setMessage,
                setSeverity,
                setAlert
            );
        } else {
            handleSubmit();
        }
    };

    const handleSubmit = () => {
        setDisable(true);
        setSubmitLoader(true);
        let data = {
            siteId: toLocation,
            fromLocation: fromLocation,
            reasonId: reason,
            products: chosenProducts,
            statusId: 3,
            supplierId: null,
            isManual: true,
            staff: userName,
        };

        axiosInstance
            .post(`${url}/purchaseOrder/Save`, data)
            .then((res) => {
                setSubmitLoader(false);
                setDisable(false);
                setChosenProducts([]);
                setPossibleProducts([]);
                setToLocation(0);
                setFromLocation(0);
                setReason(0);
                setSelectedProduct("");
                handleAlert(
                    "EDN Successfully submitted",
                    "success",
                    setMessage,
                    setSeverity,
                    setAlert
                );
            })
            .catch((err) => {
                console.log(err);
                setSubmitLoader(false);
                setDisable(false);
                handleAlert(
                    "Unable to submit - Please contact IT",
                    "error",
                    setMessage,
                    setSeverity,
                    setAlert
                );
            });
    };

    return (
        <main className="manual_edn">
            <h1 className="main_heading">Manual EDN</h1>
            <section className="header_info filter_container">
                <div className="header_indv">
                    <label>Select From Location</label>
                    <select
                        onChange={(e) =>
                            setFromLocation(parseInt(e.target.value))
                        }
                        value={fromLocation}
                    >
                        <option value={0}>Select From Location</option>
                        {siteList.length > 0
                            ? siteList.map((site: any, i: number) => {
                                  return (
                                      <option key={i} value={site.epos}>
                                          {site.name}
                                      </option>
                                  );
                              })
                            : null}
                    </select>
                </div>
                <div className="header_indv">
                    <label>Select To Location</label>
                    <select
                        onChange={(e) =>
                            setToLocation(parseInt(e.target.value))
                        }
                        value={toLocation}
                    >
                        <option value={0}>Select To Location</option>
                        {siteList.length > 0
                            ? siteList.map((site: any, i: number) => {
                                  return (
                                      <option key={i} value={site.epos}>
                                          {site.name}
                                      </option>
                                  );
                              })
                            : null}
                    </select>
                </div>
                <div className="header_indv">
                    <label>Select a Reason</label>
                    <select
                        onChange={(e) => setReason(parseInt(e.target.value))}
                        value={reason}
                    >
                        <option value={0}>Select a Reason</option>
                        <option value={129775}>New Stock</option>
                        <option value={129773}>Stock Movement</option>
                    </select>
                </div>
            </section>
            <section className="filter_container">
                <input
                    placeholder="Enter Name or Barcode or product"
                    onChange={(e) => setSelectedProduct(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            handleProductSearch();
                        }
                    }}
                    value={selectedProduct}
                />

                <Button
                    variant="contained"
                    endIcon={<SearchIcon />}
                    onClick={handleProductSearch}
                >
                    Search
                </Button>
            </section>
            {possibleProducts.length > 0 ? (
                !productLoader ? (
                    <>
                        <h3>Add products</h3>
                        <section className="products_selected">
                            <table>
                                <tbody>
                                    {possibleProducts.map(
                                        (product: any, i: number) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{product.name}</td>
                                                    <td>
                                                        <Button
                                                            variant="contained"
                                                            onClick={() =>
                                                                handleAddProduct(
                                                                    product
                                                                )
                                                            }
                                                        >
                                                            <AddIcon />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )}
                                </tbody>
                            </table>
                        </section>
                    </>
                ) : (
                    <Loader />
                )
            ) : null}
            {chosenProducts.length > 0 ? (
                <>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>Quantity</th>
                                <th>Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            {chosenProducts.map(
                                (chosenProduct: any, i: number) => {
                                    return (
                                        <tr key={i}>
                                            <td>{chosenProduct.name}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    onKeyDown={numberValidation}
                                                    onChange={(e) =>
                                                        handleQtyUpdate(
                                                            chosenProduct.productId,
                                                            parseInt(
                                                                e.target.value
                                                            )
                                                        )
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    onClick={(e) =>
                                                        handleRemoveProduct(
                                                            chosenProduct
                                                        )
                                                    }
                                                >
                                                    <DeleteIcon />
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                }
                            )}
                        </tbody>
                    </table>
                    {!submitLoader ? (
                        <section className="btn_section">
                            <Button
                                variant="contained"
                                color="success"
                                endIcon={<SendIcon />}
                                disabled={
                                    toLocation === 0 ||
                                    fromLocation === 0 ||
                                    reason === 0 ||
                                    disable
                                }
                                onClick={handleValidation}
                            >
                                Submit
                            </Button>
                        </section>
                    ) : (
                        <Loader />
                    )}
                </>
            ) : null}

            {/* MODALS */}
            <Modal open={alert}>
                <MainAlert
                    message={message}
                    severity={severity}
                    closer={setAlert}
                />
            </Modal>
        </main>
    );
}
