//?EXAMPLE FUNCTION CALL
// handleAlert("hello", "error", setMessage, setSeverity, setAlert);

//MUI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

//Styling
const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "8px",
    p: 4,
};

//Interfaces
interface Props {
    message: string;
    severity: any;
    closer: any;
}

export default function MainAlert({ message, severity, closer }: Props) {
    return (
        <Box sx={style}>
            <section className="alert_modal">
                <Alert severity={severity} className="alert_message">
                    {message}
                </Alert>

                <Button variant="contained" onClick={() => closer(false)}>
                    Close
                </Button>
            </section>
        </Box>
    );
}
