//MUI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
//Styling
const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    maxWidth: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "8px",
    p: 4,
};
//Interfaces
interface Props {
    close: any;
    handleSubmit: any;
    message: string;
    disable: boolean;
}

export default function ConfirmModal({
    close,
    handleSubmit,
    message,
    disable,
}: Props) {
    return (
        <Box sx={style} className="confirm_modal">
            <h2>Confirm Check</h2>
            <h4>{message}</h4>
            <section className="button_section">
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => close(false)}
                >
                    No, go back
                </Button>
                <Button
                    variant="contained"
                    color="success"
                    onClick={handleSubmit}
                    disabled={disable}
                >
                    Yes, submit
                </Button>
            </section>
        </Box>
    );
}
