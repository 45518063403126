const handleAlert = (
    message: string,
    severity: any,
    setMessage: any,
    setSeverity: any,
    setOpen: any
) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
};

export { handleAlert };
