//React
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
//Styling
import "./styling/nav.scss";
//Images
import nitroPosLogo from "../images/nitropos-logo.png";
// import nitroPosLogo from "../images/NITROPOS_LOGO.svg";
//MUI
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { Divider } from "@mui/material";

//Inpage Styling
const linkStyle = {
    textDecoration: "none",
    color: "#2c53a0",
};
const menuItemStyle = {
    fontWeight: 600,
    fontSize: "1.1rem",
};

export default function Nav() {
    const navigate = useNavigate();
    const [role, setRole] = useState("");

    useEffect(() => {
        let userDetails = localStorage.getItem("userDetails");
        if (userDetails) {
            let parsedUserDetails = JSON.parse(userDetails);
            setRole(parsedUserDetails.role);
        }
    }, []);

    //MENU MUI START--------------------------------------------------
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const logout = async () => {
        localStorage.removeItem("userDetails");
        window.location.href = "https://nitropos.co.uk";
    };
    //MENU MUI END----------------------------------------------------

    return (
        <nav>
            <img
                src={nitroPosLogo}
                alt="NitroPos Logo"
                className="nav_nitropos_logo"
                onClick={() => navigate("/")}
            />

            <section className="nav_menu">
                <Button
                    id="menu_icon"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                >
                    {open ? (
                        <MenuOpenIcon fontSize="large" />
                    ) : (
                        <MenuIcon fontSize="large" />
                    )}
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                >
                    <Link to="/" style={linkStyle}>
                        <MenuItem style={menuItemStyle} onClick={handleClose}>
                            PickList
                        </MenuItem>{" "}
                    </Link>
                    <Divider />

                    {role != "Packer" && role !== "" ? (
                        <>
                            {" "}
                            <Link to="/manual-edn" style={linkStyle}>
                                <MenuItem
                                    style={menuItemStyle}
                                    className="menu_item"
                                    onClick={() => {
                                        handleClose();
                                    }}
                                >
                                    Manual EDN
                                </MenuItem>
                            </Link>
                            <Divider />
                            <Link
                                to="https://www.nitropos.co.uk/staff-management"
                                style={linkStyle}
                            >
                                <MenuItem
                                    style={menuItemStyle}
                                    className="menu_item"
                                    onClick={() => {
                                        handleClose();
                                    }}
                                >
                                    Staff Management
                                </MenuItem>
                            </Link>
                            <Divider />
                            <Link to="/quick-edit" style={linkStyle}>
                                <MenuItem
                                    style={menuItemStyle}
                                    onClick={handleClose}
                                >
                                    Quick Edit
                                </MenuItem>{" "}
                            </Link>
                            <Divider />
                            <Link
                                to="https://www.nitropos.co.uk/warehouse/epos-products"
                                style={linkStyle}
                            >
                                <MenuItem
                                    style={menuItemStyle}
                                    onClick={handleClose}
                                >
                                    EPOS Products
                                </MenuItem>{" "}
                            </Link>
                            <Divider />
                            <Link
                                to="https://www.nitropos.co.uk/warehouse/tiktok"
                                style={linkStyle}
                            >
                                <MenuItem
                                    style={menuItemStyle}
                                    onClick={handleClose}
                                >
                                    TikTok
                                </MenuItem>{" "}
                            </Link>
                            <Divider />
                            <Link
                                to="https://www.nitropos.co.uk/warehouse/disposable-script"
                                style={linkStyle}
                            >
                                <MenuItem
                                    style={menuItemStyle}
                                    onClick={handleClose}
                                >
                                    Script
                                </MenuItem>{" "}
                            </Link>
                            <Divider />
                            <Link
                                to="https://www.nitropos.co.uk/cctv-status"
                                style={linkStyle}
                            >
                                <MenuItem
                                    style={menuItemStyle}
                                    onClick={handleClose}
                                >
                                    CCTV Status
                                </MenuItem>{" "}
                            </Link>
                        </>
                    ) : (
                        <Link
                            to="https://www.nitropos.co.uk/"
                            style={linkStyle}
                        >
                            <MenuItem style={menuItemStyle} onClick={logout}>
                                Logout
                            </MenuItem>{" "}
                        </Link>
                    )}
                </Menu>
            </section>
        </nav>
    );
}
